<template>
  <v-container>
    <v-card v-if="!dataLoaded">
      <v-card-title>Schedule Change Form (Loading...)</v-card-title>
      <v-card-text>Loading your current course schedule and any previously submitted course changes. This should only take a few seconds.</v-card-text>
    </v-card>
    <template v-else>
      <v-card style="margin-bottom: 1em;">
        <v-card-title>Schedule Change Form: {{ termLabel }}</v-card-title>
        <v-card-text>This is where you can view and request changes to your current schedule (add/drop courses)<span v-if="isUpperClassman">, change the grading mode for classes between standard grading and pass/fail,</span> and view the status of requested changes.</v-card-text>
      </v-card>
      <v-alert v-for="({ text, color, icon, light, bordered, border, outlined }, index) in preMessages" :key="'pre-message-'+index" :color="color" :colored-border="true" border="left" light elevation="2">
        <template v-slot:prepend>
          <v-icon :color="color" style="margin-right: 16px;width:27px;text-align:center;">{{ icon }}</v-icon>
        </template>
        {{ text }}
      </v-alert>
      <v-tabs v-model="tab">
        <v-tab v-if="timeline.length > 0">Timeline</v-tab>
        <v-tab>Make Changes</v-tab>
        <v-tab>View Schedule</v-tab>
        <v-spacer></v-spacer>
        <v-btn disabled text>Credit Hours: {{ totalCredits }}</v-btn>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="timeline.length > 0" class="timelineTab">
          <v-timeline dense style="margin:1em 1em 0 0">
            <v-timeline-item v-for="(message, index) in timeline" :key="'message-'+index" :color="message.color || 'info'" :icon="message.icon || 'fal fa-comment'" :class="!(message.icon) && message.pidm !== user.pidm ? 'reverse-icon' : ''">
              <v-card @click="message.expanded = !message.expanded" style="cursor:pointer">
                <v-card-text :style="message.expanded ? 'padding-bottom:10px' : 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;padding-bottom:10px;'" v-html="message.text"></v-card-text>
                <v-card-text style="font-size:.8em;opacity:.7;padding-top:0">{{ message.name }} -- {{ stringFormatDate(message.date) }}</v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col>
              <v-list>
                <v-list-item v-if="isUpperClassman">
                  <v-list-item-avatar>
                    <v-icon color="info">fal fa-edit</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-subtitle style="white-space:normal">As an upper classmen, you can choose to change the grading mode for non-major credit from standard grading to pass/fail. Click on this icon to change the grade mode (hover over the icon to view the current status). Your advisor and the Records office will verify course eligibility for each grade mode change requested.</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon color="error">fas fa-times-circle</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-subtitle style="white-space:normal;">To drop a course, click this icon on the course you wish to drop.</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon color="success">fal fa-undo</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-subtitle style="white-space:normal;">Click this icon to undo the change. Note that you cannot undo a change once you have submitted it.</v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="lockedCount > 0">
                  <v-list-item-avatar>
                    <v-icon color="error">fal fa-lock</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-subtitle style="white-space:normal;">This changes have been submitted and are pending a response. You cannot edit these changes.</v-list-item-subtitle>
                </v-list-item>
              </v-list>
              <v-card-text>
                <h3>Class Schedule</h3>
              </v-card-text>
              <v-list>
                <course-entry v-for="(item, index) in schedule" :key="item.crn" :item="item" :is-upper-classman="isUpperClassman" @remove="removeClass(item, index)" @change-mode="changeGradeMode(item, index)" @undo="undoChange(item, index)"></course-entry>
              </v-list>
              <v-toolbar flat>
                <add-class-dialog v-if="allowAdd" :term="term" :is-upper-classman="isUpperClassman" :schedule="schedule" @add="addClass"></add-class-dialog>
                <v-btn color="error" @click="undoAllChanges()" :disabled="!isChanged">Undo All Changes</v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-sheet height="600" id="courseCalendar">
            <v-calendar type="week" :events="classWeek" first-interval="7" :event-color="(event) => event.removed || ['DC', 'DD'].includes(event.regStatus) ? 'red' : (event.original ? 'blue' : 'green')"></v-calendar>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
      <v-card>
        <v-card-text>
          <v-alert v-if="postMessage !== ''" color="red" icon="fal fa-exclamation-triangle" colored-border border="left" elevation="2">{{ postMessage }}</v-alert>
          <v-checkbox v-if="postMessage !== ''" v-model="confirmStudentStausChange" label="I understand that, upon approval, my student status will change." :rules="required"></v-checkbox>
          <p>Please explain in the comment box below your reason for the schedule change or any other comments you may have.</p>
          <v-textarea v-model="comments" label="Reason/Comments" outlined style="margin-top:1em" :rows="comments.length < 100 ? 3 : 5"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="info" @click="save()" :disabled="!isChanged || (postMessage !== '' && !confirmStudentStausChange) || isSaving">{{ isSaving ? 'Saving...' : 'Save Schedule' }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-container>
</template>
<style>
#courseCalendar div.v-calendar-daily_head-day-label {
  display: none;
}
#courseCalendar div.v-calendar-daily__pane {
  height: 720px !important;
}
#courseCalendar .theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday, #courseCalendar .theme--light.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
  color: rgba(0, 0, 0, 1)
}
#courseCalendar .theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-weekday, #courseCalendar .theme--dark.v-calendar-daily .v-calendar-daily_head-day.v-past .v-calendar-daily_head-day-label {
  color: rgba(255, 255, 255, 1)
}
.timelineTab {
  background-color: #EEEEEE;
}
.theme--dark .timelineTab {
  background-color: #333333;
}
</style>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
import { buildCourseBlock, addToCalendar, checkConflict } from '@/components/student/schedule-change/functions'

export default {
  components: {
    CourseEntry: () => import('@/components/student/schedule-change/StudentCourseEntry'),
    AddClassDialog: () => import('@/components/student/schedule-change/addClassDialog')
  },
  setup (props, { root, emit }) {
    const dataLoaded = ref(false)
    const term = ref('202060')
    const termLabel = ref('')
    const preMessages = ref([])
    const postMessage = ref('')
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const tab = ref(0)
    const schedule = ref([])
    const lockedCount = ref(0)
    const isChanged = ref(false)
    const includedCRNs = ref([])
    const classWeek = ref([])
    let dt = new Date()
    dt.setDate(dt.getDate() - dt.getDay())
    const calStart = ref(dt.toISOString().substr(0, 10))
    const allowAdd = ref(true)
    const allowRemove = ref(true)
    const comments = ref('')
    const scDataId = ref('')
    const classLevel = ref('FR')
    const isUpperClassman = ref(false)
    const timeline = ref([])

    const classService = root.$feathers.service('calendar/classes')
    const advisor = ref({
      pidm: '',
      name: '',
      email: ''
    })

    async function loadCourses () {
      schedule.value = []
      classWeek.value = []
      // totalCredits.value = 0
      let pidm = user.value.pidm
      let { data } = await root.$feathers.service('student/term-detail').find({ query: { term: term.value, pidm } })
      if (data.length > 0) {
        const { academics, schedule: sched } = data[0]
        classLevel.value = academics.classLevel || 'FR'
        if (classLevel.value === 'JR' || classLevel.value === 'SR') {
          isUpperClassman.value = true
        }
        if (sched && sched.length > 0) {
          for (let i = 0; i < sched.length; i++) {
            schedule.value.push(buildCourseBlock(sched[i]))
            addToCalendar(sched[i], true, calStart.value, classWeek.value)
            includedCRNs.value.push(sched[i].crn)
          }
        }
        if (academics && 'advisor' in academics && Array.isArray(academics.advisor) && academics.advisor.length > 0) {
          // Filter to just the primary advisor, then use the first one (there should never be more than one primary MAJR advisor)
          advisor.value = academics.advisor.filter(({ primary, code }) => (primary && code === 'COR1') || code === 'MAJR')
        }
      }
      updateCredits()
      // Load any pending schedule changes
      let { data: changeData } = await root.$feathers.service('student/schedule-change').find({ query: { term: term.value, pidm } })
      if (changeData.length > 0) {
        const { _id, status, changes, timeline: scTimeline } = changeData[0]
        scDataId.value = _id
        if (status === 'returned') {
          addMessage('Your schedule change request has been returned to you to make additional changes.', { color: 'error' })
          preMessages.value.unshift(preMessages.value.splice(preMessages.value.length - 1)[0])
        }
        if (Array.isArray(changes) && changes.length > 0) {
          for (const { crn, action, gradeMode, status, approval } of changes) {
            includedCRNs.value.push(crn)
            let included = false
            for (let i = 0; i < schedule.value.length; i++) {
              if (schedule.value[i].crn === crn) {
                // Update the item in the schedule list
                schedule.value[i].action = action
                schedule.value[i].status = status
                if (gradeMode) schedule.value[i].gradeMode = gradeMode
                if (approval) schedule.value[i].approval = approval
                // Find the entry in the classWeek array and mark it as removed
                classWeek.value.forEach((weekItem) => {
                  if (weekItem.crn === crn) {
                    weekItem.removed = true
                  }
                })
                included = true
                break
              }
            }
            if (!included) {
              let { data: classData } = await classService.find({ query: { term: term.value, crn, $populate: 'instructors.directoryId' } })
              if (classData.length > 0) {
                schedule.value.push(buildCourseBlock({ ...classData[0], action, gradeMode, status, approval }))
                addToCalendar(classData[0], false, calStart.value, classWeek.value)
              }
            }
          }
        }
        if (Array.isArray(scTimeline) && scTimeline.length > 0) {
          timeline.value = scTimeline.filter(({ visibleToStudent }) => !!visibleToStudent).reverse()
        }
      }
      updateCredits()
    }

    let requiresInstructorApproval = true

    onMounted(async () => {
      let pidm = user.value.pidm
      // Get possible terms from the term warehouse
      let { data: termData } = await root.$feathers.service('system/term').find({ query: { end: { $gt: new Date() } } })
      let terms = []
      for (let i = 0; i < termData.length; i++) {
        terms.push(termData[i].term)
      }
      console.log(terms)
      // Get term from confirmation warehouse
      let { data: confData } = await root.$feathers.service('student/term-detail').find({ query: { pidm, term: { $in: terms } } })
      if (confData.length === 0) {
        // Look to see if the student is registered for a term that has not yet started
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'No confirmation record was found for you in any active term. If the term has not yet started, please use Banner to make schedule modifications. If the term has started, please contact the Records office for assistance.' })
        root.$router.push('/')
        return
      } else {
        console.log(confData)
      }
      term.value = confData[0].term
      for (let i = 0; i < termData.length; i++) {
        if (termData[i].term === term.value) {
          termLabel.value = termData[i].label
          if ('scheduleChangeMessages' in termData[i]) {
            let now = new Date()
            for (let j = 0; j < termData[i].scheduleChangeMessages.length; j++) {
              if (termData[i].scheduleChangeMessages[j].effDate > now) {
                // Use the previous entry (if there was one)
                if (j > 0) {
                  const { add, drop, messages } = termData[i].scheduleChangeMessages[j]
                  allowAdd.value = add
                  allowRemove.value = drop
                  preMessages.value = messages
                }
                break
              }
            }
          } else {
            let start = new Date(termData[i].start)
            let end = new Date(termData[i].end)
            let now = new Date()
            let daysIntoTerm = Math.floor((now - start) / 86400000)
            let daysRemainingInTerm = Math.floor((end - now) / 86400000)
            let termLength = Math.floor((end - start) / 86400000)
            let percentageOfTermPassed = daysIntoTerm / termLength * 100
            if (daysRemainingInTerm <= 21) {
              // Last two weeks of the term through final exam week; this is assumed to be the last 3 weeks of classes
              allowAdd.value = false
              allowRemove.value = false
              addMessage('It is not possible to drop or add a class at this time.')
              addMessage('Registrations are locked for the semester.')
              addMessage('You will receive a grade for any class you are registered for.')
            } else if (percentageOfTermPassed > 60) {
              allowAdd.value = false
              addMessage('Dropping a course at this time will require the instructor to assign either a marker of "W" or grade of "F" to your academic record.', { color: 'error', icon: 'fal fa-exclamation-triangle' })
              addMessage('Because we are more than 2 weeks into the term, you can only drop courses and cannot add courses.')
              addMessage('A $20 course change fee will be applied to your tuition account for each change.', { color: 'error', icon: 'fal fa-usd-circle' })
            } else if (daysIntoTerm > 14) {
              // allowAdd.value = false
              // If we are between 2 weeks and ??? then a message is displayed that the instructor for any dropped course can choose to assign either a W or F
              addMessage('Dropping a course at this time results in a marker of "W" being recorded on your academic record for the course.', { color: 'error', icon: 'fal fa-exclamation-triangle' })
              addMessage('Any dropped course requires approval from your advisor and the Records Office.')
              addMessage('Because we are more than 2 weeks into the term, you can only drop courses and cannot add courses.')
              addMessage('A $20 course change fee will be applied to your tuition account for each change.', { color: 'error', icon: 'fal fa-usd-circle' })
            } else if (daysIntoTerm > 7) {
              // If we are between 1 and 2 weeks into the term, then a message is displayed that the instructor for any added or dropped course will need to approve the withdrawal
              addMessage('Any added or dropped course requires approval from the course instructor, your advisor, and the Records Office.')
              addMessage('A $20 course change fee will be applied to your tuition account for each change.', { color: 'error', icon: 'fal fa-usd-circle' })
            } else {
              addMessage('Any added or dropped course will require approval from your advisor and the Records Office.')
              requiresInstructorApproval = false
            }
          }
        }
      }
      await loadCourses()
      dataLoaded.value = true
    })

    function updateCredits () {
      let total = 0
      let changes = 0
      schedule.value.forEach(({ crn, action, credits, status, regStatus }) => {
        if (!isNaN(credits)) {
          if ((!action || action !== 'drop') && !['DC', 'DD'].includes(regStatus)) total += credits
        }
        if (action && (status == null || status === 'returned')) {
          changes++
        }
      })
      totalCredits.value = total

      if (changes > 0) {
        isChanged.value = true
      } else {
        isChanged.value = false
      }

      if (totalCredits.value < 12) {
        postMessage.value = 'You currently have selected fewer than 12 credit hours, which will change your status to part time. This may affect your housing selection, athletic eligibility, and any financial aid you are currently receiving.'
      } else if (totalCredits.value > 18) {
        const wlHours = schedule.value.filter(({ regStatus }) => regStatus === 'WL').reduce((tot, { credits }) => tot + credits, 0)
        postMessage.value = 'You currently have selected more than 18 credit hours (' + totalCredits.value + ' hours' + (wlHours > 0 ? ', including ' + wlHours + ' hours in wait-listed courses' : '') + '). This may cause an increase in your tuition and result in a greater balance due.'
      } else {
        postMessage.value = ''
      }
    }
    const totalCredits = ref(0)

    /**
     * This function gets called when a student clicks the icon on a course in their schedule to remove that course.
     * So we need to remove the course from the schedule array and add it to the changes array
     */
    function removeClass (item, index) {
      item.action = 'drop'
      // Find the entry in the classWeek array and mark it as removed
      classWeek.value.forEach((weekItem) => {
        if (item.crn === weekItem.crn) {
          weekItem.removed = true
        }
      })
      updateCredits()
    }
    /**
     * This function gets called when a student clicks the icon on a course in their schedule to change the grade mode.
     * So we need to remove the course from the schedule array and add it to the changes array
     */
    function changeGradeMode (item, index) {
      item.action = 'change'
      item.gradeMode = item.gradeMode === 'grade' ? 'pass-fail' : 'grade'
      updateCredits()
    }
    /**
     * This function gets called when a student clicks the icon on a course in their changes list to undo that change.
     * So this might be adding a course back to the schedule or removing a course that has been added.
     */
    function undoChange (item, index) {
      // If this is a course removal, add the course back to the schedule
      if (item.action === 'add') {
        schedule.value.splice(index, 1)
        for (let i = classWeek.value.length - 1; i >= 0; i--) {
          if (item.crn === classWeek.value[i].crn) {
            classWeek.value.splice(i, 1)
          }
        }
        for (let i = 0; i < includedCRNs.value.length; i++) {
          if (includedCRNs.value[i] === item.crn) {
            includedCRNs.value.splice(i, 1)
            break
          }
        }
      } else if (item.action === 'drop') {
        item.action = false
        classWeek.value.forEach((weekItem) => {
          if (item.crn === weekItem.crn) {
            weekItem.removed = false
          }
        })
      } else if (item.action === 'change') {
        item.action = false
        item.gradeMode = (item.gradeMode === 'grade' ? 'pass-fail' : 'grade')
        item.status = null
      }
      updateCredits()
    }
    /**
     * Adds a class to the changes list. This is only done from the "Add Class" dialog so we need to close that dialog
     * then get the class information for the selected class, making sure to populate the instructor's information.
     */
    function addClass ({ id, passFail }) {
      root.$feathers.service('calendar/classes').find({ query: { _id: id, $populate: 'instructors.directoryId' } }).then(({ data }) => {
        if (data.length > 0) {
          includedCRNs.value.push(data[0].crn)
          schedule.value.push({ ...buildCourseBlock(data[0]), action: 'add', gradeMode: passFail ? 'pass-fail' : 'grade' })
          addToCalendar(data[0], false, calStart.value, classWeek.value)
          updateCredits()
        }
      })
    }

    const isSaving = ref(false)
    async function save () {
      isSaving.value = true
      // First, make sure there are no conflicts between the classes in the schedule + pending changes against the proposed changes (or even between proposed changes)
      let newChanges = []
      let hasConflict = false
      let instructorEmails = []
      let text = ''
      schedule.value.forEach((row) => {
        if (row.action) {
          if (row.action !== 'drop') {
            row.meetingBase.forEach((meetingRow) => {
              if (checkConflict(meetingRow, schedule.value, row.crn)) {
                hasConflict = true
              }
            })
          }
          if (!hasConflict && (!('status' in row) || row.status == null || row.status === 'returned')) {
            let temp = { crn: row.crn, action: row.action }
            if (isUpperClassman.value && (row.action === 'add' || row.action === 'change')) temp.gradeMode = row.gradeMode
            if (requiresInstructorApproval) {
              temp.approval = { pidm: row.instructorPidm, emailSent: true }
              if (!row.instructorEmailSent) {
                instructorEmails.push({ email: row.instructorEmail, approve: row.action === 'add' })
              }
            }
            temp.status = 'pending'
            newChanges.push(temp)
            text += '<li>' + (row.action === 'drop' ? 'Dropped' : (row.action === 'add' ? 'Added' : 'Changed grade mode for')) + ' ' + row.title.split(' - ')[0] + '</li>'
          }
        }
      })
      text = 'Submitted ' + newChanges.length + ' schedule change' + (newChanges.length === 1 ? '' : 's') + ':' + text
      if (newChanges.length === 0) {
        alert('No changes detected; unable to save.')
        isSaving.value = false
        return
      }
      if (hasConflict) {
        alert('There is a conflict between one or more proposed changes and a class either in your current schedule or in your proposed changes. This must be rectified before submitting. Please check the "View Schedule" tab for assistance in locating the conflict.')
        isSaving.value = false
        return
      }
      if (comments.value === '') {
        alert('You must enter a reason for the schedule change into the comment box')
        isSaving.value = false
        return
      }
      if (scDataId.value !== '') {
        // Load the data from the server for this object so we can find the differences in the changes
        let data = await root.$feathers.service('student/schedule-change').get(scDataId.value)
        let changes = []
        if (data.changes.length > 0) {
          // Any changes that were set to 'returned', remove from the original data array so we can add the new changes; keep all other items
          data.changes.forEach((row) => {
            if (row.status !== 'returned') changes.push(row)
          })
        }
        newChanges.forEach((row) => {
          changes.push(row)
        })
        let obj = {
          status: 'pending',
          advisorApproval: advisor.value.map(({ pidm }) => { return { pidm, date: null } }),
          recordsApproval: {
            pidm: null,
            date: null
          },
          submitDate: new Date(),
          changes,
          $push: {
            timeline: [
              {
                pidm: user.value.pidm,
                name: user.value.name,
                date: new Date(),
                text,
                icon: 'fal fa-file-plus',
                color: 'info',
                visibleToStudent: true
              }
            ]
          }
        }
        if (comments.value !== '') {
          obj.$push.timeline.push({
            pidm: user.value.pidm,
            name: user.value.name,
            date: new Date(),
            text: comments.value,
            icon: 'fal fa-comment',
            color: 'info',
            visibleToStudent: true
          })
        }
        await root.$feathers.service('student/schedule-change').patch(scDataId.value, obj)
      } else {
        let obj = {
          term: term.value,
          pidm: user.value.pidm,
          bannerId: user.value.bannerId,
          name: user.value.name,
          status: 'pending',
          submitDate: new Date(),
          changes: newChanges,
          advisorApproval: advisor.value.map(({ pidm }) => { return { pidm } }),
          timeline: [
            {
              pidm: user.value.pidm,
              name: user.value.name,
              date: new Date(),
              text,
              icon: 'fal fa-file-plus',
              color: 'info',
              visibleToStudent: true
            }
          ]
        }
        if (comments.value !== '') {
          obj.timeline.push({
            pidm: user.value.pidm,
            name: user.value.name,
            date: new Date(),
            text: comments.value,
            icon: 'fal fa-comment',
            color: 'info',
            visibleToStudent: true
          })
        }
        await root.$feathers.service('student/schedule-change').create(obj)
      }
      // Send an email to any instructors
      for (const { email, approve } of instructorEmails) {
        await root.$feathers.service('system/email').create({
          to: email,
          from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
          subject: approve ? 'Student schedule change requires your approval' : 'Student schedule change for your acknowledgement',
          html: user.value.preferred + ' ' + user.value.last + ' has submitted a schedule change that requires your ' + (approve ? 'approval' : 'acknowledgement') + '. Please follow the link below to access the Schedule Change system to ' + (approve ? 'approve' : 'acknowledge') + ' this requested schedule change.<br/><br/><a href="https://portal.covenant.edu/student/schedule-change">https://portal.covenant.edu/student/schedule-change</a>'
        })
      }
      // Send an email to the student's advisor(s)
      for (const { email } of advisor.value) {
        await root.$feathers.service('system/email').create({
          to: email,
          from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
          subject: 'Student schedule change requires your approval',
          html: user.value.preferred + ' ' + user.value.last + ' has submitted a schedule change that requires your approval as their advisor. Please click on the link below to access the Schedule Change system to approve this request.<br/><br/><a href="https://portal.covenant.edu/student/schedule-change">https://portal.covenant.edu/student/schedule-change</a>'
        })
      }
      // Send an email to the Records office to notify them of the submission
      await root.$feathers.service('system/email').create({
        to: 'recordsoffice@covenant.edu',
        from: { email: 'report-email@covenant.edu', name: 'Schedule Change' },
        subject: 'Student schedule change submitted',
        html: user.value.preferred + ' ' + user.value.last + ' has submitted a schedule change. Please click on the link below to access the Schedule Change system to review this request.<br/><br/><a href="https://portal.covenant.edu/student/schedule-change">https://portal.covenant.edu/student/schedule-change</a>'
      })
      root.$store.dispatch('main/snackbar', { color: 'success', text: 'Schedule change submitted successfully.' })
      root.$router.push('/student/schedule-change')
    }

    function addMessage (text, options, isPost) {
      let color = 'info'
      let icon = 'fas fa-exclamation'
      let bordered = true
      let border = 'left'
      let light = true
      let outlined = false
      if (options) {
        if ('color' in options) color = options.color
        if ('icon' in options) icon = options.icon
        if ('bordered' in options) bordered = options.bordered
        if ('border' in options) border = options.border
        if ('light' in options) light = options.light
        if ('outlined' in options) outlined = options.outlined
      }
      preMessages.value.push({ text, color, icon, bordered, border, light, outlined })
    }

    function undoAllChanges () {
      for (let i = schedule.value.length - 1; i >= 0; i--) {
        let { action, status, gradeMode } = schedule.value[i]
        if (action && (status == null || status === 'returned')) {
          if (action === 'add') {
            schedule.value.splice(i, 1)
          } else if (action === 'change') {
            schedule.value[i].gradeMode = (gradeMode === 'grade' ? 'pass-fail' : 'grade')
            schedule.value[i].action = false
          } else {
            schedule.value[i].action = false
          }
        }
      }
      updateCredits()
    }

    const confirmStudentStausChange = ref(false)
    const required = ref([ (v) => !!v || 'Required' ])

    return {
      dataLoaded,
      term,
      termLabel,
      user,
      preMessages,
      postMessage,
      allowAdd,
      allowRemove,
      comments,
      classLevel,
      isUpperClassman,
      timeline,
      advisor,
      scDataId,
      tab,
      schedule,
      lockedCount,
      isChanged,
      classWeek,
      calStart,
      totalCredits,
      removeClass,
      changeGradeMode,
      undoChange,
      addClass,
      addToCalendar,
      isSaving,
      save,
      addMessage,
      loadCourses,
      undoAllChanges,
      confirmStudentStausChange,
      required,
      stringFormatDate
    }
  }
}
</script>
